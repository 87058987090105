/* @import '~antd/dist/antd.css'; */

.borderblog {
  height: 1px;
  border-bottom: 1px dotted black;
}

.App {
  text-align: center;
}

.borderlineBottom {
  border-bottom: 1px solid #050c0e;
  color: #050c0e;
}

.underline {
  text-decoration: underline;
}

.backgrouGrey {
  background-color: #fafafa;
  border: 2px solid #f2f2f2;
}

.italic {
  font-style: italic;
}

.italicNsmall {
  font-style: italic;
}

.balancesheet-text {
  /* font-size: smaller; */
  margin: 1px 1px 1px 1px;
}

.balancesheet-linktext {
  /* font-size: smaller; */
  color: cornflowerblue;
  margin: 1px 1px 1px 1px;
}

.balancesheet-amt {
  font-size: smaller;
  float: right;
}

.fixed-assets-table > .ant-table-thead {
  background-color: #1da57a !important;
}

/* .current-assets-table{
  margin-top: -23px;
} */

.customBold {
  font-weight: 1200;
  font-size: 1.2em;
  padding: 1px 1px;
  margin-bottom: 1px !important;
}
.customBold2 {
  font-weight: 800;
  font-size: 1em;
  padding: 1px 1px;
  margin-bottom: 1px !important;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* custom-start  */
li.ant-menu-item span a {
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
}

li.ant-menu-item span.span2 a {
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
}

li.ant-menu-item span.span3 a {
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
}

li.ant-menu-item span a:hover {
  color: #fff;
  text-decoration: none;
}

li.ant-menu-item span.span2 a:hover {
  color: #fff;
  text-decoration: none;
}

li.ant-menu-item span.span3 a:hover {
  color: #fff;
  text-decoration: none;
}

li.ant-menu-item2 span a {
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
}

li.ant-menu-item2 span a:hover {
  color: rgb(79, 62, 177);
  text-decoration: none;
}

.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-btn {
  font-weight: 800;
  font-size: 0.95em;
}

span {
  font-weight: 550;
  font-size: 0.95em;
}

.span2 {
  font-weight: 600;
  font-size: 1em;
}

.span3 {
  font-weight: 800;
  font-size: 1.2em;
}

.ant-table-row > td {
  font-weight: 450;
}

.ant-form-item-label {
  font-weight: 700;
}

/* custom-end  */

/* org-chart-start  */
.orgDiv {
  min-width: 100px;
  text-align: center;
  vertical-align: middle;
  font-family: arial, helvetica;
  cursor: default;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 3px;
  background-color: #edf7ff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#edf7ff),
    to(#4dbce9)
  );
}

.selectdOrgDiv {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#edf7ff),
    to(#cff09e)
  );
}
/* org-chart-end  */

/* org-chart-v2-start */
.initechNode {
  padding: 2px;
  width: 130px;
  display: inline-block;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 3px;
}

.initechNode p {
  font-size: 11px;
}

#initechOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
  border-right: solid 1px grey;
}

#initechOrgChart .nodeLineBorderTop {
  border-top: solid 1px grey;
}
/* org-chart-v2-end  */

/* menu layout - start */
.ant-menu-item img.menu-image {
  margin-right: 10px !important;
  width: 13px !important;
  color: rgba(255, 255, 255, 0.15) !important;
}

.ant-menu-item2 img.menu-image {
  margin-right: 10px !important;
  width: 13px !important;
  color: rgba(255, 255, 255, 0.15) !important;
}

.ant-menu-submenu-title > span img.menu-image {
  margin-right: 10px !important;
  width: 13px !important;
  color: rgba(255, 255, 255, 0.15) !important;
}

.ant-menu-inline-collapsed > .ant-menu-item img.menu-image + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}

.ant-menu-inline-collapsed > .ant-menu-item2 img.menu-image + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
/* menu layout - end */

.border-top-primary {
  border-top-width: 5px;
  border-top-style: solid;
  border-top-color: #1da57a !important;
  /* border-top-color: rgba(233,152,76) !important; */
}

.box-shadow {
  border-top-style: solid;
  border-top-color: coral;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: "#fff";
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

/* custom qleditor start*/
.ql-display-container {
  border: none;
}

.ql-display-container2 {
  border: none;
}

.ql-display-container .ql-display-editor h1 {
  font-size: 2em;
}

.ql-display-container .ql-display-editor h2 {
  font-size: 1.5em;
}

.ql-display-container .ql-display-editor h3 {
  font-size: 1.17em;
}

.ql-display-container2 .ql-display-editor2 h1 {
  font-size: 1.5em;
}

.ql-display-container2 .ql-display-editor2 h2 {
  font-size: 1.12em;
}

.ql-display-container2 .ql-display-editor2 h3 {
  font-size: 0.87em;
}

.ql-display-container2 .ql-display-editor2 p {
  font-size: 0.87em;
}

.ql-display-container > .ql-display-editor {
  margin-top: 130px;
  padding: 10px 3px;
  box-sizing: border-box;
  line-height: 0.5;
  /* height: 100%; */
  outline: none;
  /* overflow-y: auto; */
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  border: 1px solid #d9d9d9;

  word-break: break-all;
  overflow-wrap: break-word;
  line-height: 15px;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
  word-break: break-word;
}

.ql-display-container2 > .ql-display-editor2 {
  margin-top: 20px;
  padding: 15px 5px;
  width: 100%;
  box-sizing: border-box;
  line-height: 0.5;
  /* height: 100%; */
  outline: none;
  /* overflow-y: auto; */
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  border: 1px solid #d9d9d9;
  overflow-y: visible;
}
/*
    .ql-display-editor > p{
    font-size: 1.17em;
  margin-bottom:0em !important;
}
*/

@page {
  size: A4;
  margin: 0;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  html,
  body {
    width: 210mm;
    height: 297mm;
  }

  .pageBreak {
    /* page-break-after: always; */
    page-break-before: always;
  }

  .ql-display-editor {
    /* font-size:5mm; */
    display: block;
    width: 250mm;
    padding: 5px;
    box-sizing: border-box;
    /* line-height: 1.4; */
    outline: none;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    /* white-space: pre-wrap;
      word-wrap: break-word; */
    border: 1px solid #d9d9d9;

    /* word-break: break-all;
      overflow-wrap: break-word;
      line-height: 18px; */
  }

  .ql-display-editor > p {
    margin-top: -10px;
  }

  .invoiceFooter {
    text-align: center;
    /* position: fixed; */
    position: absolute;
    /* bottom: 40px; */
    flex-shrink: 0;
    margin-top: 5px;
  }

  .receiptFooter {
    /* text-align:center; */
    /* position: fixed; */
    /* position: absolute; */
    /* bottom: 40px; */
    /* flex-shrink: 0; */
    /* margin-top: 1px; */
  }
  .paymentvoucherFooter {
    text-align: left;
    position: fixed;
    /* position: absolute; */
    /* bottom: 40px; */
    flex-shrink: 0;
    margin-top: 100px;
    left: 100px;
    bottom: 0;
  }

  .moneyInTransitStatement {
    text-align: left;
    position: fixed;
    flex-shrink: 0;
    margin-top: 20px;
    left: 50px;
  }

  .soaFooter {
    text-align: center;
    /* position: fixed; */
    position: absolute;
    /* bottom: 40px; */
    margin-top: 1px;
    display: block;
    float: none;
    overflow: visible;
    /* page-break-after: always; */
    /* page-break-inside: auto; */
  }

  .reminderFooter {
    text-align: left;
    flex-shrink: 0;
    bottom: 0;
  }
}

.buttonRed {
  background-color: #ff7875; /* Red */
  border-color: #ff7875;
  color: white;
}

.buttonGreen {
  background-color: #73d13d; /* Green */
  border-color: #73d13d;
  color: white;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 8px 8px;
}

.ant-hide-tilte-table {
  display: none !important;
}

.ant-card-head2 {
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 16px;
  margin-bottom: -1px;
  min-height: 48px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 250;
}
.ant-card-head2 > .ant-card-head-title2 {
  padding: 16px 0;
  font-size: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1 1;
  display: inline-block;
}

.ant-table-small
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 4px 4px;
}

/* custom qleditor end*/

td.td-padding-0 {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.table-title {
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  padding-bottom: 5px;
}

.table-row-light {
  border-top: white;
  background-color: red;
  position: "absolute";
}

.button-inner {
  background-color: #1DA57A;
  width: 10px;
  height: 10px;
  border-radius: 15px;
  margin-left: "auto";
  margin-right: "auto";
  margin-top: "auto";
  margin-bottom: "auto";
}

.button-diselected {
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

