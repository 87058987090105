@import "~react-image-gallery/styles/css/image-gallery.css";
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.ant-card{
  margin-bottom: 10px
}

.chartTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.chartTable td, .chartTable th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.chartTableHead0{
  width: 3%;
}
.chartTableHead00{
  width: 24%;
}

.chartTableHead01{
  width: 27%;
}

.chartTable tr:nth-child(even) {
  background-color: #dddddd;
}

.wrap-span-tree{
    width: 100%;
}

.spanMiddleName{
  overflow: hidden;
  text-overflow: ellipsis;
  float: right;
  position: relative;
  width: 50%;
  /* position: absolute; */
  /* margin-left: 14%; */
}

.spanMiddleType{
  margin-right: 25%;
  float: right;
}

.registrationNo{
  font-size: 15px;
  font-weight: 600;
}

.ant-tree-node-content-wrapper{
  width: 100% !important;
}

.wrap-reactToPrint{
  position: relative;
  display: inline-block;
  width: 100%;
}

.wrap-reactToPrint button{
  float: right;
}

.img-header-arr{
  font-size: 12px;
  margin-bottom: 0;
}

.fixed-assets-table tr td{
  padding: 0 !important;
  margin: 0 !important;
}

.liabilities-table tr td{
  padding: 0 !important;
  margin: 0 !important;
}

.current-assets-table tr td{
  padding: 0 !important;
  margin: 0 !important;
}

.enquity-table tr td{
  padding: 0 !important;
  margin: 0 !important;
}

.jmbsNo{
  font-weight: 100;
  font-size: 15px;
}

.alignRight{
    float: right;
}

.ql-editor {
white-space: normal!important;
}

.fade-enter {
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-appear {
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-leave {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-enter.fade-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-appear.fade-appear-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  animation-name: fadeOut;
  animation-play-state: running;
}


.fadeTable-enter {
  opacity: 0;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: running;
}

.fadeTable-appear {
  opacity: 0;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: running;
}

.fadeTable-leave {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: running;
}

.fadeTable-enter.fade-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fadeTable-appear.fade-appear-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fadeTable-leave.fade-leave-active {
  animation-name: fadeIn;
  animation-play-state: running;
}



.fadeLayout-enter {
  opacity: 0;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fadeLayout-appear {
  opacity: 0;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fadeLayout-leave {
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fadeLayout-enter.fadeLayout-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fadeLayout-appear.fadeLayout-appear-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fadeLayout-leave.fadeLayout-leave-active {
  animation-name: fadeOut;
  animation-play-state: running;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@media print {
  .printTable{
    width:950px;
  }
  .printTable td{
    padding-top:3px !important;
    padding-bottom:3px !important
  }

  td.title-desc, td.reportAmt, th.reportAmt, td span.total{
    /* width:40px; */
    font-size:8px !important
  }

  td.title-desc{
    width:50px
  }

  p.balancesheet-linktext{
    color:rgba(0, 0, 0, 0.65) !important
  }

}

.ant-table-row-expand-icon-cell .ant-table-row-expand-icon.ant-table-row-collapsed{
  display:none
}
.wrap-zoom-img {
  position: relative;
  height: 34em;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
}
.wrap-zoom-img img {
  /* width: 100% !important; */
  /* height: auto !important; */
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.news-icon-placement{
  margin-left: 5px;
  margin-bottom: 5px;
  margin-right: 2px;

}
.dot-img-overlay {
  left: 0;
  right: 0;
  background-color: #FBB040;
  height: 6px;
  width: 75%;
  position: absolute;
  bottom: 0px;
  margin: auto;
  z-index: 2;
}
.dot-img {
  cursor: pointer;
  height: 100px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.dot-img img {
  width: 100%;
  height:100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.ant-table-last-row{
  border-bottom: 40px;
  border-width: 7px;
  border-color:  rgba(0, 0, 0, 0.65);
  border-style: solid;
  height: 100px;
}

